<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <login-links-comp></login-links-comp>
                <div class="logo_panel" style="padding-top: 50px">
                    <div class="logo">
                        <img src="../../assets/images/logo/oneplay/oneplay_logo.png">
                    </div>
                    <div class="ment line">
                        스포츠 게임 <span style="color: #079e39">원플레이</span>에 오신것을 환영합니다.
                    </div>
                    <div class="ment mt10" style="font-size: 12px;color: #c4c4c3">
                        <p>실명인증기간 1년이 지난 회원님께서는 실명인증을 해주시기 바랍니다.</p>
                        <p> 실명인증후 정상 로그인이 가능합니다.</p>
                    </div>

                    <div class="terms_box" style="background-color: #343434">
                        <div class="join-container">
                            <p style="text-align: left;font-size: 10px;color: #9f9fa1;height: 28px;line-height: 28px;display: block">
                                <span  style="font-size: 16px;font-weight: bold">실명인증</span> 휴대폰 인증 또는 아이핀 인증후 정상이용이 가능합니다.
                            </p>
                            <div class="form-group">

                                <div class="labels">
                                    <p>실명인증</p>
                                </div>
                                <div class="infos">
                                    <button class="btn03 btn_check_phone" @click="authphone">휴대폰인증</button>
                                    <button class="btn03 btn_check_phone text-primary">아이핀인증</button>
                                </div>
                                <!-- 본인인증 서비스 팝업을 호출하기 위해서는 다음과 같은 form이 필요합니다. -->
                                <form name="form_chk" method="post">
                                    <input type="hidden" name="m" value="checkplusService">						<!-- 필수 데이타로, 누락하시면 안됩니다. -->
                                    <input type="hidden" name="EncodeData" v-model="sencData">
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>
        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import {checkUserName, checkNickName, getVerificationCode, register, getSencdata} from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LoginLinksComp from "../../components/links/LoginLinksComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";

    export default {
        name: "realnameauthentication",
        components: {
            FootComp,
            RightBarBannerComp, RightBarComp, LoginLinksComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                sencData:null,
            }
        },
        methods: {
            authphone(){
                this.fnPopup()
            },
            fnPopup(){
                window.open('', 'popupChk', 'width=375, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
                document.form_chk.target = "popupChk";
                document.form_chk.submit();
            }
        },
        created() {
            getSencdata(4).then(res=>{
                if(res.data.success){
                    this.sencData = res.data.data
                    console.log(this.sencData)
                }else {
                    this.$swal({
                        title: res.data.msg,
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                }
            })
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/lore.css");
</style>